import React, { useEffect, useState } from 'react';
import {
  Flex
} from '@fluentui/react-northstar';
import { fetchPromptsParam, fetchPromptsParamFromConfig } from '../services/awsService';
import { useIntl } from 'react-intl';
import { FontIcon } from '@fluentui/react';

interface QuestionProps {
  setQuestionValue: (data: any) => void;
}

const LeftQuestionCategory: React.FC<QuestionProps> = ({ setQuestionValue }) => {
  const intlInstance = useIntl();
  const [activeSections, setActiveSections] = useState<string[]>([]);
  const [selectedValue, setSelectedValue] = React.useState({} as any);
  const [promptQuestions, setPromptQuestions] = React.useState([] as any);

  const toggleSection = (sectionId: any) => {
    setActiveSections((prevActiveSections) => {
      if (prevActiveSections.includes(sectionId)) {
        return prevActiveSections.filter((id) => id !== sectionId);
      } else {
        return [...prevActiveSections, sectionId];
      }
    });
  };

  const setQuestion = (event: any) => {
    const question = event.currentTarget.dataset.content;
    setSelectedValue(question);
  }

  const onPromptFetch = (promptQuestion: any) => {
    // Code to parse SSM param config
    const promptQues = promptQuestion.promptConfig;
    // const promptQues = JSON.parse(promptQuestion);
    // const promptQues = intlInstance.formatMessage({ id: 'promptConfig' });
    setPromptQuestions(promptQues);
  }

  useEffect(() => {
    // fetchPromptsParam(onPromptFetch);
    fetchPromptsParamFromConfig(onPromptFetch);
    
  }, []);

  useEffect(() => {
    setTimeout(() => {
      console.log('Prompt question ', promptQuestions[0]);
      if (!!promptQuestions && promptQuestions.length >= 1) {
        toggleSection(promptQuestions[0].title);
      }
    }, 500);    
  }, [promptQuestions]);
  
  

  useEffect(() => {
    setQuestionValue(selectedValue);
  }, [selectedValue]);

  return (
    <div>
      <Flex gap="gap.small" className='top-card' padding="padding.medium" hAlign='center' vAlign='center'>
        <div className="accordion">
          <div className='accordion-title'>{intlInstance.formatMessage({ id: 'promptTitle' })}</div>
          {promptQuestions.map((item: any) => {
            if ((item.subCategory === true)) {
              return (
                <div className={`section ${activeSections.includes(item.title) ? 'active' : ''}`} id={item.title} key={item.title}>
                  <div className="section-header" onClick={() => toggleSection(item.title)}>
                    <div className="section-arrow"></div>
                    <img className="section-image" src={item.imagePath} alt={item.title} />
                    <span className="section-title">{item.title}</span>
                  </div>
                  <div className="section-content">
                    {item.categories.map((category: any) => (
                      <div className={`section ${activeSections.includes(category.opcoCode) ? 'active' : ''}`} key={category.opcoCode} id={category.opcoCode}>
                        <div className="section-header" onClick={() => toggleSection(category.opcoCode)}>
                          <div className="section-arrow"></div>
                          <span className="section-title">{category.opcoCode}</span>

                        </div>
                        <div className="section-content">
                          <ul className="list-items">
                            {category.opcoQuestion.map((question: any) => (
                              <li className="list-item" data-content={question.prompt} onClick={setQuestion} key={question.promptDisplay}>{question.promptDisplay}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            } else {
              return (
                <div className={`section ${activeSections.includes(item.title) ? 'active' : ''}`} id={item.title} key={item.title}>
                  <div className="section-header" onClick={() => toggleSection(item.title)}>
                    <div className="section-arrow"></div>
                    <img className="section-image" src={item.imagePath} alt={item.title} />
                    <span className="section-title">{item.title}</span>
                  </div>
                  <div className="section-content">
                    <ul className="list-items">
                      {item.categories.map((category: any) => (
                        <li className="list-item" data-content={category.prompt} onClick={setQuestion} key={category.promptDisplay}>{category.promptDisplay}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              );
            }

          })}
          <div className='accordion-footer'>
            <h2><FontIcon className="disclaimer-title-icon" iconName="Warning" />{intlInstance.formatMessage({ id: 'disclaimerTitle' })}</h2>
            
            <p className='disclaimer-text1' dangerouslySetInnerHTML={{ __html: intlInstance.formatMessage({ id: 'disclaimerText1' }) }} />
            <p className='disclaimer-text2' dangerouslySetInnerHTML={{ __html: intlInstance.formatMessage({ id: 'disclaimerText2' }) }} />
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default LeftQuestionCategory;