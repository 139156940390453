// src/intl.tsx
import { createIntl, createIntlCache } from 'react-intl';

const getUserLocale = () => {
  // const userLanguage = navigator.language;
  const userLanguage = 'en-US';
  // Add logic to map userLanguage to supported languages if needed
  return userLanguage;
};

const cache = createIntlCache();
const locale = getUserLocale();

// Dynamically load messages based on the user's language
const messages = require(`./${locale}.json`);

const intl = createIntl(
  {
    locale,
    messages,
  },
  cache
);

export default intl;
