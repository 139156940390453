import FingerprintJS from '@fingerprintjs/fingerprintjs';
import * as CryptoJS from 'crypto-js';
const apiFetchTokenUrl = process.env.REACT_APP_HOSTED_UI + 'token';

export const fetchToken = async (data: string) => {
  // const languageLocale = localStorage.getItem('lang');
  try {
    const response = await fetch(apiFetchTokenUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data,
    });

    const errorResponse = {
      "messages": [
          "Unable to get any response."
      ]
    }

    if (!response.ok) {
      console.error(`Error sending message: ${response.statusText}`);
      return errorResponse;
    }

    return response.json();
  } catch(error) {
    console.error(error);
    const errorResponse = {
      "messages": [
          "Unable to get any response."
      ]
    }
    return errorResponse;
  }
};

/**
   * @description This method will generate visitor ID
   *
   * @return {*}
   * @memberof authService
   */
export const getVisitorId = async () => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      const fpPromise = FingerprintJS.load();
      const fp = await fpPromise;
      const result = await fp.get();
      localStorage.setItem('visitorId', result.visitorId)
      resolve(result.visitorId)
    }
    catch (e) {
      reject(e);
    }
  });
}

/**
   * (description): Generates the userid
   * (parameter) : string
   * (returns) : string
   * (memberof) : AuthService
   */
export const getChallenge = (vId: any):any => {
  const codeVerifierHash = CryptoJS.SHA256(vId).toString(CryptoJS.enc.Base64);
  const codeChallenge = codeVerifierHash
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
  return codeChallenge
}


