import { Loader, Provider, teamsTheme } from '@fluentui/react-northstar';
import React, { FC, useEffect } from 'react';


interface LogoutProps { }

const Logout: FC<LogoutProps> = () => {
   useEffect(() => {
      localStorage.clear();
      const url = `${process.env.REACT_APP_HOSTED_UI_LOGOUT}?client_id=${process.env.REACT_APP_USERPOOL_WEBCLIENT}&logout_uri=${process.env.REACT_APP_CALLBACK_LOGOUT}`
      // const url = `${process.env.REACT_APP_HOSTED_UI_LOGOUT}?response_type=code&scope=openid+profile+email&client_id=${process.env.REACT_APP_USERPOOL_WEBCLIENT}&redirect_uri=${process.env.REACT_APP_CALLBACK_LOGOUT}`
      window.open( url, "_self");
    }, []);
   
   return (
      <Provider
      className='login-provider'
      theme={teamsTheme}>

      <Loader size="larger" label="Loading..." labelPosition="below" />
    </Provider>
   )
};

export default Logout;
